import axios from 'axios'

import InterWebviewBridge, {
  interWbNavigate,
  IWbHttpData,
  interWbHttp,
  interWbSession,
  WbEnvironments,
  IWbISafeResponse,
  interWbAuth,
  IWbAppInfo,
  IWbTic,
  IWbTac,
} from '@interco/inter-webview-bridge'

import { HttpMethod, TParamsRequest, TResponseType } from './types'

export default class {
  static goBack = async () => {
    await interWbNavigate.requestGoBack()
  }

  static sendAnalyticsEvent = ({
    name,
    params,
  }: {
    name: string
    params?: Record<string, string>
  }): Promise<unknown> | null => interWbSession.requestAnalytics(name, params)

  static formatResponse = (response: unknown) =>
    typeof response === 'string' && response[0] === '{' ? JSON.parse(response || '[]') : response

  static doRequest = async <T>({
    headers,
    pathname = '',
    method,
    data,
    host,
    timeout,
  }: TParamsRequest): Promise<TResponseType<T>> => {
    const requestHeader = {
      ...headers,
      'Content-Type': 'application/json',
    }

    const url = `/mobile${pathname}`
    const payload = typeof data === 'string' ? JSON.parse(data) : data
    let bridgeResponse = {} as IWbHttpData

    if (InterWebviewBridge.getInstance().getEnvironment() === WbEnvironments.BROWSER) {
      return axios({
        headers,
        url: `${host}${pathname}`,
        method,
        data,
        timeout,
      })
    }
    switch (method) {
      case HttpMethod.GET: {
        bridgeResponse = await interWbHttp.get(url, requestHeader, {
          timeout,
        })
        break
      }
      case HttpMethod.POST: {
        bridgeResponse = await interWbHttp.post(url, payload, requestHeader, {
          timeout,
        })
        break
      }
      case HttpMethod.PUT: {
        bridgeResponse = await interWbHttp.put(url, payload, requestHeader, {
          timeout,
        })
        break
      }
      case HttpMethod.DELETE: {
        bridgeResponse = await interWbHttp.delete(url, requestHeader, {
          timeout,
        })
        break
      }
      default:
        break
    }

    const { httpStatus = 0, headers: responseHeaders = {} } = bridgeResponse

    let parsedData = {} as T
    if (typeof bridgeResponse.response === 'string') {
      parsedData = JSON.parse(bridgeResponse.response || '{}')
    }
    return {
      data: parsedData,
      status: httpStatus,
      headers: responseHeaders,
    }
  }

  static openNativeBrowser = (url = ''): Promise<unknown> => interWbNavigate.openNativeBrowser(url)

  static isAndroid = () => /(Android)/.test(navigator.userAgent)

  static getUserInfo = async () => {
    try {
      return await interWbSession.getUserInfo()
    } catch (e) {
      return undefined
    }
  }

  static updateToolbar = async (title: string, show = false) => {
    try {
      return await interWbSession.editToolbar(title, show)
    } catch (e) {
      return e
    }
  }

  static async isafeFGTS(value: number): Promise<IWbISafeResponse | undefined> {
    const CATEGORY = '75'
    const CHECK_TYPE = '5'
    const FEATURE = 'AUTORIZACAO_CREDITO_PESSOAL'

    return interWbAuth.requestISafe(CATEGORY, CHECK_TYPE, FEATURE, value)
  }

  static getEnvironment = () => InterWebviewBridge.getInstance().getEnvironment()

  static openDeeplink = (url: string) => interWbNavigate.openDeepLink(url)

  static async openPdf(url: string, title: string): Promise<void> {
    try {
      await interWbNavigate.openPdf(title, url)
    } catch (e) {
      // TODO: Tratar erro
      console.error(e)
    }
  }

  static async getAppInfo(): Promise<IWbAppInfo | undefined> {
    try {
      return interWbSession.getAppInfo()
    } catch (e) {
      return undefined
    }
  }

  static async isWeb(): Promise<boolean> {
    return InterWebviewBridge.getInstance().getEnvironment() === WbEnvironments.BROWSER
  }

  static async tic(params: Pick<IWbTic, 'action' | 'name'>): Promise<IWbTic | undefined> {
    if (await this.isWeb()) return undefined
    return interWbSession.tic({
      action: params.action,
      module: 'antecipacao_fgts_v5',
      name: params.name,
      params: {},
    })
  }

  static async tac(
    params: Pick<IWbTac, 'actions' | 'name' | 'isError'>,
  ): Promise<IWbTac | undefined> {
    if (await this.isWeb()) return undefined
    return interWbSession.tac({
      actions: params.actions,
      module: 'antecipacao_fgts_v5',
      name: params.name,
      params: {},
      isError: params.isError,
    })
  }
}
