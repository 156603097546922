export type TParamsRequestBrigde = {
  headers?: Record<string, string>
  pathname: string | unknown
  method: HttpMethod
  data?: unknown
}

export enum HttpMethod {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
}

export enum HttpStatus {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNABAILABLE = 503,
  NO_CONTENT = 204,
  ACCEPT = 202,
  CREATED = 201,
}

export class ServiceResponseError extends Error {
  message: string

  data: unknown

  status?: number

  constructor({ message, data, status }: TServiceResponseError) {
    super(message)

    this.message = message
    this.data = data
    this.status = status
  }
}

export type TServiceResponseError = {
  status?: number
  message: string
  data: unknown
}

export type TResponseType<T> = {
  status: number
  data: T
  headers: Record<string, string>
}

export type TParamsRequest = {
  method: HttpMethod
  headers?: Record<string, string>
  host: string | unknown
  pathname?: string | unknown
  data?: unknown
  engine?: string
  timeout?: number
}

export enum TicTacEvents {
  SIMULAR = 'simular',
}
